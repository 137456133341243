import {
  Component,
  Input,
  OnInit,
  NgZone,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "src/app/services/data.service";
import { SessionService } from "src/app/services/session.service";
import {
  FormBuilder,
  ValidatorFn,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors,
} from "@angular/forms";
import { Location } from "@angular/common";
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { AudioRecordingService } from "src/app/services/audio-recording.service";
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})

export class HeaderComponent implements OnInit {
  private onDestroy$ = new Subject();
  private debounceSubject = new Subject<string>();
  suggestions: any[] = [];
  showSuggestion: boolean = false;
  oIncomingEvent: any;
  question: string = '';
  messagesWithAI: any = [];
  tableItems: any[] = [];
  headers: string[] = [];
  chatLoader: boolean = false;
  errorIndices: number[] = [];
  historyLoaderBoolean: boolean = false;
  conversationId: string = '';
  imageUrlBot: string = '';
  bDisplayNotification: boolean = false;
  changeNameForm: FormGroup;
  @Input() toggleSidebar: any;
  testuser: any;
  oUserdetails: any;
  imageUrl: any;
  notificationCount: number;
  lnotifications: any = [];
  startNotification: number = 1;
  passwordMode: boolean;
  newPasswordMode: boolean;
  repeatPasswordMode: boolean;
  totalItems: any;
  changePasswordForm: FormGroup;
  error: boolean = false;
  oldPassword: any;
  bDisplayErrorBlock: boolean;
  displayErrorBlock: boolean;
  resMessage: { message: any; responseType: any; color: any };
  selectedItem: any = {};
  bLoading: boolean;
  filename: string | null;
  errorCode: any;
  filesToUpload: any = [];
  itemImages: any = [];
  file: File | null;
  userProfileImage: any;
  profileImg: any;
  navProfileImage: boolean = false;
  fullName: string;
  uploadImage: boolean = false;
  showHeader: boolean = true;
  copyTextMessage: boolean = false;
  intervalId: any;
  dismissApi: any;

  //Audio 
  disableIocns: boolean = false;
  isAudioRecording = false;
  audioRecordedTime;
  audioBlob;
  audioName;
  audioStream;
  audioConf = { audio: true }
  audioBlobUrl;
  micText: boolean = false;
  showTenants: boolean=false;
  uploadingModal: any;
  displayUploadModal: boolean=false;

  //*************----------------------------------- constructor -------------------------------------------************//
  constructor(
    private oRoute: Router,
    private modalService: NgbModal,
    private sessionService: SessionService,
    private DataService: DataService,
    private ngZone: NgZone,
    private oFormBuilder: FormBuilder,
    private sessionStorage: SessionService,
    private location: Location,
    private router: Router,
    private ref: ChangeDetectorRef,
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer) {

    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isAudioRecording = false;
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.audioRecordedTime = time;
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.audioBlob = data.blob;
      this.audioName = data.title;
      this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      this.ref.detectChanges();
    });
  }
  //*************----------------------------------- ngOnInit -------------------------------------------************//
  ngOnInit(): void {
    this.oUserdetails = this.sessionService.SessionService_GetUserDetails();
    this.initializeChangeNameForm();
    this.patchFormWithUserDetails();
    this.DataService.reloadHeader.subscribe(result => {
      if (result) {
        this.oUserdetails = this.sessionService.SessionService_GetUserDetails();
        this.profileImg = this.oUserdetails.pictureUrl;
        this.initializeChangeNameForm();
        this.patchFormWithUserDetails();
      }
    })
    this.getNotificationsCount();
    this.startFunctionCallInterval();

    this.userProfileImage = this.oUserdetails.pictureUrl;
    if (this.navProfileImage == true) {
      this.profileImg = this.oUserdetails.pictureUrl;
    }
    else {
      this.profileImg = this.oUserdetails.pictureUrl;
    }
    this.location.onUrlChange((url: string) => {
      this.dismissModal();
      this.bDisplayErrorBlock = false;
    });

    // Subscribe to the debounced subject
    this.debounceSubject.pipe(
      debounceTime(500), // Debounce time set to 0.5 seconds
      takeUntil(this.onDestroy$) // Unsubscribe when the component is destroyed
    ).subscribe((inputValue: string) => {
      // Call the actual function after debounce
      this.HeaderComponent_GetSuggestions(inputValue);
    });

    this.DataService.updateFalseCount.subscribe(result => {
      if(result)
      {
        console.log( this.uploadingModal);
        
        this.uploadingModal=result
      }
      // Handle the falseCount value as needed
    });
  }

  toggleHeader() {
    this.showHeader = !this.showHeader; // Toggle the visibility of the header
  }

  initializeChangeNameForm() {
    let regex = /^[^\s].*[^\s]$/;
    this.changeNameForm = new FormGroup({
      id: new FormControl(),
      createdAt: new FormControl(),
      updatedAt: new FormControl(),
      deleted: new FormControl(),
      userId: new FormControl(),
      firstName: new FormControl(null, [Validators.required, Validators.pattern(regex)]),
      lastName: new FormControl(null, [Validators.required, Validators.pattern(regex)]),
      role: new FormControl(),
      orgName: new FormControl(),
      emailVerified: new FormControl(''),
      tenantId: new FormControl(),
      tenantName: new FormControl(),
      termsAgreed: new FormControl(),
      privacyAgreed: new FormControl(),
      mobilePhone: new FormControl(),
      companyName: new FormControl(),
      authTenantId: new FormControl(),
      tenantProps: new FormGroup({
        tenantType: new FormControl(),
        displayName: new FormControl(),
        name: new FormControl(),
      }),
      fullName: new FormControl(),
      uid: new FormControl(),


    });

    // *Subscribe to value changes in firstname and lastname controls
    this.changeNameForm.get('firstName').valueChanges.subscribe(() => {
      this.updateFullName();
    });
    this.changeNameForm.get('lastName').valueChanges.subscribe(() => {
      this.updateFullName();
    });
  }

  // *Combine firstname and lastname and set the value of fullName
  updateFullName() {
    const firstname = this.changeNameForm.get('firstName').value;
    const lastname = this.changeNameForm.get('lastName').value;
    const fullName = `${firstname} ${lastname}`;
    this.changeNameForm.get('fullName').setValue(fullName);
  }

  patchFormWithUserDetails() {
    this.changeNameForm.patchValue(this.oUserdetails);
  }

  dismissModal() {
    this.modalService.dismissAll();
    this.HeaderComponent_StartNewChat()
    this.initializeChangeNameForm();
    this.audioRecordingService.stopRecording();
    this.isAudioRecording = false;
    this.micText = false;
    this.disableIocns = false;
  }

  loadAvatar() {
    this.imageUrl = '../../../../assets/images/profileimg.png';
  }

  loadDropdownProfile(){
    this.profileImg = "../../../../assets/images/profileimg.png";
  }
  loadNotificationDummyImage(item){
    item.userPictureUrl='../../../../assets/images/profileimg.png'
  }

  // *Image Select 
  onFileSelect(event) {
    const selectedFiles: FileList = event.target.files as FileList;
    const validFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    const invalidFiles = Array.from(selectedFiles).filter(
      (file) => !validFileTypes.includes(file.type)
    );
    if (invalidFiles.length > 0) {
      this.displayAlertMessage("Only images are allowed", "error", "danger");
      return;
    }
    this.filesToUpload = event.target.files;
    this.uploadFiles();
    this.filename = "";
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.filename = this.file.name;
      console.log('File Name~*', this.filename);
    }
  }

  // *Upload Image
  uploadFiles() {
    this.uploadImage = true;
    for (const file of this.filesToUpload) {
      const timestamp = new Date().getTime();
      const filename = `${timestamp}_${file.name}`;
      const formData: FormData = new FormData();
      formData.append("file", file, filename);
      formData.append("functionParam", '{"userId" : "' + this.oUserdetails.id + '"}')
      formData.append("folder", "public/ProfileImages");
      formData.append("functionName", "zzUpdateProfilePicture");
      formData.append("makePublic", "true");
      this.DataService.UploadImage(formData).subscribe({
        next: (response) => {
          this.userProfileImage = "https://storage.googleapis.com/" + response.bucketName + "/" + response.folderName + "/" + response.fileName;
          this.changeNameForm.addControl('pictureUrl', new FormControl(this.userProfileImage));
          this.uploadImage = false;
        },
        error: (error) => {
          //this.displayAlertMessage(error.detailMessage, "error", "danger");
          console.error(error)
          console.error(error);
        },
        complete: () => { },
      });
    }
  }


  initializeChangePasswordForm() {
    this.changePasswordForm = new FormGroup(
      {
        userId: new FormControl(null),
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
        ]),
        rptPassword: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
        ]),
        oldPassword: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
        ]),
      },
      { validators: this.passwordMatchValidator }
    );
    this.patchFormValues();
  }

  //*************----------------------------------- Modals -------------------------------------------************//
  ChangePasswordModal(changePassword) {
    this.modalService.open(changePassword, {
      centered: true,
      size: "md",
      backdrop: 'static'
    });
    this.initializeChangePasswordForm();
  }

  dismiss() {
    this.repeatPasswordMode = false;
    this.newPasswordMode = false;
    this.passwordMode = false;
    this.modalService.dismissAll();
  }

  dropdownSettings = {
    singleSelection: true,
    idField: "id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "Unselect All",
    itemsShowLimit: 3,
    allowSearchFilter: false,
    dropdownPosition: "bottom",
  };

  //*************----------------------------------- Apis -------------------------------------------************//

  getNotification(isScroll) {
    if (this.notificationCount >= 1 || !isScroll) {
      this.startNotification = 1;
    }
    const body = {
      start: this.startNotification,
      size: 10,
    };
    this.DataService.GetNotifications(body).subscribe({
      next: (result: any) => {
        if (result.failed) {
          console.log("error");
        } else {
          const newNotification = result.data.notifications;
          this.totalItems = result.data.totalItems;
          if (isScroll) {
            this.lnotifications.push(...newNotification);
          } else {
            this.lnotifications = newNotification;
          }
          for (let nIndex = 0; nIndex < this.notificationCount; nIndex++) {
            if (nIndex >= this.lnotifications?.length) {
              break;
            } else {
              this.lnotifications[nIndex]["new"] = "true";
            }
          }
          this.getNotificationViewUpdate();
        }
      },
      error: (error) => {
        //this.displayAlertMessage(error.detailMessage, "error", "danger");
        console.error(error)
      },
      complete: () => { },
    });
  }

  getNotificationsCount() {
    const body = {};
    this.dismissApi = this.DataService.GetNotificationsCount(body).subscribe({
      next: (result: any) => {
        if (result.failed) {
          console.log("error");
        } else {
          this.notificationCount = result.data.count;
        }
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => { },
    });
  }

  getNotificationViewUpdate() {
    const body = {};
    this.DataService.GetNotificationViewUpdate(body).subscribe({
      next: (result: any) => {
        if (result.failed) {
          console.log("error");
        } else {
        }
      },
      error: (error) => {
        //this.displayAlertMessage(error.detailMessage, "error", "danger");
        console.error(error)
      },
      complete: () => { },
    });
  }

  changeUserPassword() {
    if (this.changePasswordForm.valid) {
      this.bLoading = true;
      let body = this.oUserdetails;
      body.oldPassword = this.changePasswordForm.controls["oldPassword"].value;
      body.password = this.changePasswordForm.controls["password"].value;
      body.rptPassword = this.changePasswordForm.controls["rptPassword"].value;
      this.DataService.ChangePassword(body).subscribe({
        next: (result) => {
          if (result.failed) {
            this.errorCode = result.code;
            if (this.errorCode !== 401) this.displayAlertMessage(result.detailMessage, "error", "danger");
            this.bLoading = false;
          } else {
            if (this.oUserdetails.userId === result?.props?.user.userId) {
              this.sessionStorage.SessionService_SetUserDetails(
                result?.props?.user
              );
            }
            this.modalService.dismissAll();
            this.changePasswordForm.reset();
            this.displayAlertMessage("Success", "success", "success");
          }
          this.bLoading = false;
        },
        error: (error) => {
          this.displayAlertMessage(
            error.message == "null" ? "error" : error.message,
            "error",
            "danger"
          );
        },
        complete: () => { },
      });
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  //*************----------------------------------- Functions -------------------------------------------************//
  removeLeadingSpaces(event: KeyboardEvent) {
    if (event.key === " " && event.target instanceof HTMLInputElement) {
      const inputElement = event.target as HTMLInputElement;
      if (inputElement.selectionStart === 0) {
        event.preventDefault();
      }
    }
  }

  onChange() {
    this.errorCode = "";
  }

  showCurrentPassword() {
    this.passwordMode = !this.passwordMode;
  }

  showNewPassword() {
    this.newPasswordMode = !this.newPasswordMode;
  }

  showRepeatPassword() {
    this.repeatPasswordMode = !this.repeatPasswordMode;
  }

  patchFormValues() {
    this.changePasswordForm.patchValue({
      userId: this.oUserdetails.userId,
    });
    this.changePasswordForm.get("userId").disable();
  }

  HeaderComponent_logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.oRoute.navigate(["/auth/login"]);
  }

  startFunctionCallInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      let checkLogin = localStorage.getItem("lsUserDetails");
      if (checkLogin) {
        if (!this.bDisplayNotification) {
          this.getNotificationsCount();
        }
      }
    }, 60000);
  }


  displayNotifications() {
    const isScroll = false;

    this.getNotification(isScroll);
    setTimeout(() => {
      this.bDisplayNotification = !this.bDisplayNotification;
    }, 200);
  }

  clickOutside() {
    if (this.bDisplayNotification == true) {
      this.getNotificationsCount();
    }

    this.bDisplayNotification = false;
  }

  onScroll(event: any) {
    const isScroll = true;
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.totalItems - 10 > this.startNotification) {
        this.startNotification += 10;
        this.getNotification(isScroll);
      }
    }
  }
  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed.
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.dismissApi.unsubscribe();
  }

  passwordMatchValidator: ValidatorFn = (
    control: FormGroup
  ): ValidationErrors | null => {
    const password = control.get("password");
    const rptPassword = control.get("rptPassword");
    if (
      password.dirty &&
      rptPassword.dirty &&
      password.value !== rptPassword.value
    ) {
      console.log("Password mismatch error:", control.errors);
      this.error = true;
      return { passwordMismatch: true };
    } else {
      console.log("no error:");
      this.error = false;
      return null;
    }
  };

  closeAlert() {
    this.bDisplayErrorBlock = false;
  }

  //*************----------------------------------- displayAlertMessage -------------------------------------------************//

  displayAlertMessage(
    sIncommingMessage,
    sIncommingResponseType,
    sIncommingColor
  ) {
    this.bDisplayErrorBlock = true;
    this.resMessage = {
      message: sIncommingMessage,
      responseType: sIncommingResponseType,
      color: sIncommingColor,
    };
    setTimeout(() => {
      this.bDisplayErrorBlock = false;
    }, 3000);
  }

  // *Update Profile Modal 
  UpdateProfileModal(updateProfile) {
    // this.modalService.open(updateProfile, {
    //   centered: true,
    //   size: "lg",
    //   backdrop: 'static'
    // });
    // // this.oUserdetails = this.sessionService.SessionService_GetUserDetails();
    // this.userProfileImage = this.oUserdetails.pictureUrl;
    // this.initializeChangeNameForm();
    // this.patchFormWithUserDetails();
    this.router.navigate(['/settings'])
  }

  // *Update Profile Modal 
  blingbotQuickChat(quickChat) {
    this.modalService.open(quickChat, {
      centered: true,
      size: "md",
      backdrop: "static"
    });
  }


  adjustTextareaHeight(event: any): void {
    // // Get the textarea element
    this.oIncomingEvent = event;
    const textarea = event.target;
    // Set textarea height to auto to calculate the actual content height
    textarea.style.height = 'auto';
    // Set the textarea's height to match its scrollHeight
    textarea.style.height = textarea.scrollHeight + 'px';
    // Check if the content height exceeds the maximum height (200px) and apply scrolling
    if (textarea.scrollHeight > 200) {
      textarea.style.overflowY = 'auto';
      textarea.style.height = '200px';
    } else {
      // If the content is smaller than 200px, remove scrolling and set the height accordingly
      textarea.style.overflowY = 'hidden';
    }
  }

  // *Save Profile
  saveProfile() {
    // if (this.changeNameForm.valid) {
    //   const formValueWithoutPictureUrl = { ...this.changeNameForm.value };
    //   delete formValueWithoutPictureUrl.pictureUrl;
    //   this.DataService.EditUser(formValueWithoutPictureUrl).subscribe({
    //     next: (result: any) => {
    //       if (result.status === "success") {
    //         this.profileImg = this.userProfileImage;
    //         this.navProfileImage = true;
    //         this.sessionService.SessionService_SetUserDetails(result.props.user)
    //         this.fullName = this.oUserdetails.firstName + ' ' + this.oUserdetails.lastName;
    //         this.oUserdetails = this.sessionService.SessionService_GetUserDetails();
    //         this.dismissModal();
    //         this.displayAlertMessage(result.detailMessage, "success", "success");
    //       } else {
    //         if(result.detailMessage.includes('250')){
    //           this.displayAlertMessage('Limit Exceed! First Or Last name limit is 250', "error", "error");
    //         }
    //         else{
    //           this.displayAlertMessage(result.detailMessage, "error", "error");
    //         }
    //       }
    //     },
    //     error: (error) => {
    //       this.displayAlertMessage(error.detailMessage, "error", "error");
    //       console.error(error);
    //     },
    //     complete: () => { },
    //   });
    // }
  }


  //BlingBot  Functions

  HeaderComponent_ConversationWithAIModelOnEnter(oIncomingEvent) {
    if (oIncomingEvent.key === 'Enter' && !oIncomingEvent.shiftKey) {
      oIncomingEvent.preventDefault(); // Prevent the default behavior of creating a new line
      this.HeaderComponent_StartChatWithAI();
    }
  }

  HeaderComponent_StartChatWithAI() {
    this.HeaderComponent_CloseSuggestionList()
    this.question = this.question.trim();
    if (this.question == '') {
      return
    }
    else {
      if (this.messagesWithAI.length > 0) {
        this.messagesWithAI[this.messagesWithAI.length - 1].suggestions = []
      }
      const message = {
        question: this.question
      }
      this.messagesWithAI.push(message)
      this.HeaderComponent_ScrollToBottom();
      this.HeaderComponent_ChatWithAIModel();
    }
  }

  HeaderComponent_ChatWithAIModel() {
    this.chatLoader = true;
    const body = {
      conversationId: this.conversationId == '' ? '' : this.conversationId.toString(),
      question: this.question,
      fileId: [],
      isPdf: false
    };
    this.question = '';
    if (this.oIncomingEvent != undefined) this.oIncomingEvent.target.style.height = "auto"
    this.suggestions = [];
    this.DataService.GetResponse(body).subscribe({
      next: (result: any) => {
        if (result.status == 'failed') {
          console.log(result.detailMessage)
        }
        else {
          if (this.conversationId == '') {
            this.DataService.callConversationHistory.next(true)
          }
          this.conversationId = result.data.conversationId;
          const message = {
            answer: result.data.llm_response ? result.data.llm_response : '',
            heading: result.data.heading ? result.data.heading : '',
            rows: result.data.rowData ? this.HeaderComponent_CreateRowsTable(result.data.rowData) : [],
            tableHeaders: result.data.rowData ? this.HeaderComponent_CreateHeadersTable(result.data.rowData) : [],
            suggestions: result.data.suggestions ? result.data.suggestions : [],
            images: result.data.images_urls ? JSON.parse(result.data.images_urls) : [],
            response: result.data
          }
          this.suggestions = [];
          this.messagesWithAI[this.messagesWithAI?.length - 1] = Object.assign({}, this.messagesWithAI[this.messagesWithAI?.length - 1], message);
          this.HeaderComponent_ScrollToBottom();
        }
        this.chatLoader = false;
      },
      error: (error) => {
        console.error(error);
        this.chatLoader = false;
      },
      complete: () => { },
    });
  }

  HeaderComponent_HandleError(index: number) {
    this.errorIndices.push(index);
  }

  HeaderComponent_GetErrorImage(index: number): string | null {
    return this.errorIndices.includes(index) ? '../../../../../assets/images/image-not-found.jpeg' : null;
  }


  HeaderComponent_CreateRowsTable(dataString) {
    let headers: string[];
    let tableItems: any[] = [];
    const rows = dataString.split('\n');
    headers = rows[0].split('|');
    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split('|');
      const item = {};
      for (let j = 0; j < headers.length; j++) {
        item[headers[j]] = columns[j];
      }
      tableItems.push(item);
    }
    return tableItems;
  }

  HeaderComponent_CreateHeadersTable(dataString) {
    let headers: string[];
    const rows = dataString.split('\n');
    headers = rows[0].split('|');

    return headers;
  }

  HeaderComponent_OnTextareaInput(event) {
    const target = event.target;
    this.question = target.value;
    this.question = this.question.trim();
    // Emit the input value to the debounceSubject
    if (this.question != '' && this.question.length >= 4) this.debounceSubject.next(this.question);
  }

  HeaderComponent_GetSuggestions(query) {
    if (!this.chatLoader) {
      const body = {
        query: query
      };
      this.DataService.GetSuggestions(body).subscribe({
        next: (result: any) => {
          if (result.status == 'failed') {
            console.log(result.detailMessage)
          }
          else {
            this.suggestions = result.data.suggestions
            if (this.messagesWithAI.length > 0 && this.suggestions.length > 0) {
              this.messagesWithAI[this.messagesWithAI.length - 1].suggestions = []
            }
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => { },
      });
    }
  }

  HeaderComponent_CloseSuggestionList() {
    this.suggestions = [];
  }

  HeaderComponent_LoadAvatar() {
    this.userProfileImage = '../../../../../assets/images/profile.jpeg'
  }

  HeaderComponent_IsAtLeastOneKeyNotEmpty(data): boolean {
    const keysToCheck = ["heading", "rows", "suggestions", "answer", "images"];
    return keysToCheck.some(key =>
      (data[key] && data[key]?.length > 0 && (key=='rows' || key == 'images'|| key == 'suggestions')) || (data[key] !== "" && data[key] && (key=='heading' || key == 'answer'))
    );

  }

  HeaderComponent_SelectSuggestion(commingQuey) {
    this.question = commingQuey;
    this.HeaderComponent_StartChatWithAI()
  }

  HeaderComponent_ScrollToBottom() {
    setTimeout(() => {
      const divnative = document.getElementById('scrollContainer');
      // divnative.scrollTop = divnative.scrollHeight;
      divnative.scrollTo({
        top: divnative.scrollHeight,
        behavior: 'smooth'
      });
    }, 100);
  }

  HeaderComponent_NavigateToBlingBot() {
    this.sessionService.SessionService_SetConversationId(this.conversationId);
    if (this.location.path() == '/help-center') {
      const currentUrl = this.location.path();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
    else {
      this.router.navigate(['/help-center'])
    }
    this.modalService.dismissAll();
    this.audioRecordingService.stopRecording();
    this.isAudioRecording = false;
    this.micText = false;
    this.disableIocns = false;
  }

  HeaderComponent_StartNewChat() {
    this.chatLoader = false;
    this.messagesWithAI = [];
    this.headers = [];
    this.tableItems = [];
    this.suggestions = [];
    this.question = '';
    this.conversationId = '';
  }

  //AUDIO Functions



  startAudioRecording() {
    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this.question = '';
      this.suggestions = [];
      this.disableIocns = true;
      setTimeout(() => {
        if (this.isAudioRecording) this.micText = true;
      }, 1000);
      this.audioRecordingService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.micText = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopAudioRecording() {
    if (this.isAudioRecording) {
      this.audioRecordingService.stopRecording();
      this.isAudioRecording = false;
      this.micText = false;
      setTimeout(() => {
        this.uploadAudioFile();
      }, 100)
    }
  }
  uploadAudioFile() {
    if (this.audioBlob) {
      const formData: FormData = new FormData();
      formData.append("file", this.audioBlob, this.audioName);
      formData.append("folder", "public/AudioFiles");
      formData.append("makePublic", "true");
      this.DataService.UploadImage(formData).subscribe({
        next: (result) => {
          if (result.error) {
          } else {
            this.googleTranscriptionTrigger(result.folderName, result.fileName)
          }

        },
        error: (error) => {
        },
      });
    }
    else {
      this.disableIocns = false;
    }
  }

  googleTranscriptionTrigger(folderName, fileName) {
    let body = {
      fileName: folderName + '/' + fileName
    }
    this.DataService.SpeechToText(body).subscribe({
      next: (result) => {
        if (result.error) {
        } else {
          this.disableIocns = false;
          if (result.data.text != '') {
            this.question = result.data.text
            // this.adjustTextareaHeight();
            if (this.question != '' && this.question.length >= 4) this.debounceSubject.next(this.question);
          }
          this.audioBlob = undefined;
          this.audioName = undefined;
          this.audioBlobUrl = null;
        }
      },
      error: (error) => {
      },
    });
  }
  goToTenants(){
    this.showTenants=true
  }
  toggle() {
    this.displayUploadModal = !this.displayUploadModal;
  }
}
