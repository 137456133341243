import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { canActivateguard } from "./guards/auth.guard";
const routes: Routes = [
  { path: "", redirectTo: "auth/login", pathMatch: "full" },
  {
    path: "",
    component: BlankComponent,
    children: [
      {
        path: "auth",
        loadChildren: () =>
          import("./modules/authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        path: "",
        redirectTo: "/app/dashboard",
        pathMatch: "full",
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
      },
      {
        path: "margin-calculator", loadChildren: () => import("./modules/margin-calculator/margin-calculator.module").then((m) => m.MarginCalculatorModule),
      },
      {
        path: "usda-company-lookup", loadChildren: () => import("./modules/usda-company-lookup/usda-company-lookup.module").then((m) => m.UsdaCompanyLookupModule),
      },
    ],
  },
  {
    path: "",
    component: FullComponent,
    children: [
      {
        path: "dashboard", canActivate: [canActivateguard], loadChildren: () => import("./modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "reports", canActivate: [canActivateguard], loadChildren: () => import("./modules/reports/reports.module").then((m) => m.ReportsModule),
      },
      // {
      //   path: "survey", canActivate: [canActivateguard], loadChildren: () => import("./modules/survey/survey.module").then((m) => m.SurveyModule),
      // },
      {
        path: "settings", canActivate: [canActivateguard], loadChildren: () => import("./modules/settings/settings.module").then((m) => m.SettingsModule),
      },
      {
        path: "view-daily-diamond",
        canActivate: [canActivateguard],
        loadChildren: () =>
          import("./modules/daily-diamonds/daily-diamonds.module").then((m) => m.DailyDiamondsModule),
      },
      {
        path: "usermanager",
        canActivate: [canActivateguard],
        loadChildren: () =>
          import("./modules/user-manager/usermanager.module").then(
            (m) => m.UsermanagerModule
          ),
      },
      {
        path: "apps",
        canActivate: [canActivateguard],
        loadChildren: () =>
          import("./modules/apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "flyers-marketing",
        canActivate: [canActivateguard],
        loadChildren: () =>
          import("./modules/flyers-marketing/flyers-marketing.module").then((m) => m.FlyersMarketingModule),
      },
      {
        path: "help-center",
        canActivate: [canActivateguard],
        loadChildren: () =>
          import("./modules/help-center-main/help-center.module").then((m) => m.HelpCenterModule),
      },
      {
        path: "usda-company-lookup",
        canActivate: [canActivateguard],
        loadChildren: () =>
          import("./modules/usda-company-lookup/usda-company-lookup.module").then((m) => m.UsdaCompanyLookupModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "auth/not-found",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
