import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertsComponent } from './alerts/alerts.component';
import { DiamondLoaderComponent } from './diamond-loader/diamond-loader.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClickOutsideDirective } from '../../directive/click-outside.directive';
import { PhoneNumberFormatDirective } from './phone-number-format.directive';
import { CustomAlertsComponent } from './custom-alerts/custom-alerts.component';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { cHeadersInterceptor } from '../../interceptors/headers.interceptor';


@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    AlertsComponent,
    DiamondLoaderComponent,
    ClickOutsideDirective,
    PhoneNumberFormatDirective,
    CustomAlertsComponent,
    CustomPaginationComponent

  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    RouterModule,
    RouterModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbDropdown
  ],
  providers: [
    cHeadersInterceptor
  ],
  exports: [HeaderComponent, SidebarComponent, AlertsComponent, DiamondLoaderComponent, PhoneNumberFormatDirective, ClickOutsideDirective, CustomAlertsComponent, CustomPaginationComponent]
})
export class SharedModule { }
