<div class="navbar-2 p-15" >
    <img src="../../assets/images/turbo-logo.svg" alt="" height="35px" />
    <span class="light-logo font-14 font-600 text-white">Turbo UPC</span>
</div>

<div class="container text-center main-heading position-relative">
    <div class="text-start">
        <i class="fa fa-solid fa-arrow-left cursor me-2 font-20 back-button" routerLink="/auth/login"></i>
    </div>
    <h1 class="privacy-policy-heading">Privacy Policy</h1>
</div>

<div class="container">
    <div class="card shadow mb-4">
        <div class="card-body">
            <p class="mb-1">Contact: 616-272-5959 | Email: admin&#64;onediamondmail.com</p>
            <br />
            <p>
                Address: 678 Front Ave NW Ste 215 <br />
                Grand Rapids, MI 49504
            </p>

            <h2><strong>Privacy Policy</strong></h2>
            <p>
                Turbo UPC ("Turbo UPC" or "us" or "our") recognizes the need
                to protect the privacy of the personal information you provide to One
                Diamond concerning your access and use of our website (
                <a href="https://www.onediamondmarketing.com">https://www.onediamondmarketing.com</a> ) and our mobile
                application and related services (the "Platform").
            </p>
            <p>
                By accessing the Platform, you consent to the collection and use of your information
                by us, but only to the extent described herein. If you wish to
                revoke your consent, you may contact us using the information in the
                Privacy Policy below.
            </p>

            <p>
                Turbo UPC may make modifications, deletions, and additions to this
                Privacy Policy ("Changes") at any time. Changes will be effective: (1)
                thirty (30) days after Turbo UPC provides notice of the Changes,
                whether such information is provided through the Platform user
                interface or sent to the email address associated with your account.
            </p>

            <p>
                We are accountable for the information in our custody. Therefore, when
                we collect or use your information, we will utilize commercially
                reasonable safeguards to ensure its protection. It should be noted that
                no security procedure is currently 100% effective. Should any breach of
                your Personal Information occur, we
                will inform you as soon as possible, as required by applicable law.
            </p>

            <p>
                <strong>Type and Purpose of Collection</strong> <br />
                We collect information at various points in the Platform to facilitate
                its use by our users. Specifically, two types of information are
                collected.
            </p>

            <p>
                <strong>Non-Personal Information:</strong> <br />
                Upon accessing the Platform, certain non-personal information will be automatically collected without
                your
                knowledge or consent, such as your IP address, location data (which is
                anonymous, and where you log in through the Platform, you will be asked
                if you consent to the Platform accessing your location, which can be updated at any time in your device
                settings) and the referring website ("Non-Personal Information").
                <br />
                We use Non-Personal Information to examine our traffic and to see how our
                customers use the Platform. This information will not allow you to be
                personally identified, although we might associate it with your account. <br />
                For example, we use "cookies," which contain only certain statistical
                information. For more information on cookies, please find information
                below.
            </p>

            <hr />

            <p>
                <strong>Identifying Personal Information:</strong> <br />
                To utilize some portions of the
                Platform, you must first provide information that will allow you to be
                identified, such as your name and email address ("Personal
                Information"). This type of information will not be collected without
                your consent.
            </p>

            <p>
                We expressly acknowledge that we will not use your Personal Information for other
                purposes without your consent. Personal Information we collect will
                vary depending on how you are using the Platform, but may include,
                without limitation:
            </p>

            <ul>
                <li>Your name, mailing address, phone number and
                    email address</li>
                <li> Credit card information and charge history</li>
                <li> Unique identifiers such as
                    user name, account number and password</li>
                <li> Preferences information
                    such as product or content interests,
                    communication or marketing preferences</li>
                <li> Device information and data</li>
                <li> Google, Twitter and Facebook identity.</li>
            </ul>

            <p>
                Additionally, Personal Information may be used and disclosed to the
                extent Turbo UPC may deem reasonably necessary to enforce the terms of
                any agreement between you and Turbo UPC or to protect the rights,
                property, or safety of any person or entity.
            </p>

            <p>
                Right to Examine Information: You have the right to examine any personal
                information we collect. Should you wish to review such information,
                please send us a written request to admin&#64;onediamondmail.com. We reserve
                the right to charge you a reasonable administrative fee to access your data, as permitted by applicable
                law.
                <br />
                In some instances, we may be able to provide you with all of your
                Personal Information (for example, if the information also pertains to
                another user's data).
            </p>

            <p>
                <strong>Withdrawal Consent</strong> <br />
                You may withdraw your consent to collecting Personal Information at any
                time by emailing us. Upon receiving notice that you have revoked your
                consent, we will stop using your Personal Information within a
                reasonable time, which will vary depending on what information we have
                collected and its purpose.
            </p>

            <p>
                Please note that we will send you an email confirmation upon receipt of
                your request. Therefore, if you do not receive a confirmation email,
                please contact us again with your request. If you withdraw such consent,
                your access to the Platform may be diminished, or your ability to choose
                some options on the Platform may be limited.
            </p>

            <p><strong>Contact email:</strong> admin&#64;onediamondmail.com</p>

            <hr />

            <p>
                <strong>Sharing Information</strong> <br />
                We will not sell, rent or disclose to outside parties the information we
                collect and save, except that we may share the collected data with other
                parties as follows:
            </p>

            <ul>
                <li>
                    Affiliated Service Providers: We have agreements with various
                    affiliated service providers to facilitate the Platform's functioning,
                    with whom we may share the information we have collected. For example,
                    we may share your credit card information with the credit card service
                    provider to process your purchase.
                    <br />
                    Additionally, for example, we may use analytic services.
                </li>
                <li>
                    Where required by law: We may share collected information
                    required by law, specifically in response to a demand from government
                    authorities where such request meets the legal requirements.
                </li>
                <li>
                    Statistical Analysis: We may share Non-Personal Information and
                    aggregated information with third parties, including but not limited to
                    advertising or marketing purposes. No Personal Information will be
                    shared in this manner.
                </li>
                <li>
                    Transactions: In connection with, or during negotiations of, any
                    merger, sale of company assets, financing or acquisition, or in any
                    other situation where Personal Information may be disclosed or
                    transferred as one of our business assets.
                </li>
            </ul>

            <p>
                <strong>Newsletter</strong> <br />
                If you wish to subscribe to Turbo UPC's newsletter, we will use the
                name and email address to send it to you. You may unsubscribe at any
                time using the unsubscribe link located at the bottom of each newsletter.
            </p>

            <p>
                <strong>External Links</strong> <br />
                The Platform contains links and references to other websites. We are not
                responsible for collecting, using, and disclosing information or the
                privacy practices of such websites. We expressly disclaim any liability
                relating to it.
            </p>

            <p>
                <strong>International Visitors</strong> <br />
                The Platform is hosted in the United States and is intended for visitors
                located within the United States. If you choose to use the Service from
                the European Union or other regions of the world with laws governing
                data collection, use that may differ from US law. Please note that you
                are transferring your personal information outside those regions to the United States for
                storage and processing. Also, we may share your data from the US to
                other countries or regions in connection with storing and processing
                data, fulfilling your requests, and operating the Platform. By providing
                any information, including personal information, on or to the Service,
                you consent to such transfer, storage, and processing.
            </p>

            <p>
                <strong>International Transfer</strong> <br />
                Both Personal Information and Non-Personal Information you submit via
                the Platform are sent to Turbo UPC and processed in the USA and stored
                on secure servers located in the USA. We may also transfer such
                information to our offices and third parties. The countries concerned
                may have similar protection as your country. Where we share your data outside your
                jurisdiction, we will take reasonable steps to ensure that your privacy
                rights remain protected. You agree to this storing, processing, and
                transfer by submitting your information to the Platform.
            </p>

            <p>
                <strong>Payment Processing</strong> <br />
                Payment details you provide will be encrypted using secure sockets layer
                (SSL) technology before submitting it over the internet. Payments made
                on the Platform are made through our payment gateway provider. You will
                provide credit or debit card information directly to our payment gateway
                provider, which operates a secure server to process payment details, encrypt your
                credit/debit card information, and authorize payment. The information
                you supply to our payment gateway provider is not within our control and
                is subject to their own privacy policy and terms and conditions.
            </p>

            <hr />

            <p class="mb-0"><strong>Terms & Service</strong></p>
            <p>
                This Privacy Policy is incorporated into and forms part of the Terms of
                Service, which outlines the terms and conditions for accessing and
                using the Platform and can be found on our website.
            </p>

            <p>
                <strong>Children's Privacy</strong> <br />
                Protecting the privacy of young children is especially important. Our
                Platform is not directed to children under the age of 13. We do not
                knowingly collect personal information from children under 13 without
                parental consent. If you are under 13 years of age, please do not
                use or access the Platform at any time or in any manner. If we learn that personally
                identifiable information has been collected on the Platform from persons
                under 13 years of age without verifiable parental consent, we will take the appropriate steps to delete
                this information.
            </p>

            <p>
                <strong>Cookies and Similar Technologies</strong> <br />
                When you interact with the Platform, we try to make that experience
                simple and meaningful. When you use our Platform, our web server sends a
                cookie to your computer or mobile device (as the case may be).
                Cookies are small pieces of information that are issued to your computer or
                mobile device (as the case may be) when you visit a website or access or use a mobile
                application and which store and sometimes track information about your use of a website or
                application (as the case may be). Several cookies we use last only for
                the duration of your web or Platform session and expire when you close
                your browser or exit the Platform.
                Other cookies are used to remember when you return to the Platform and
                will last longer.
            </p>

            <p>
                We set cookies using the Platform; some are set by parties delivering
                services on our behalf. Most web and mobile device browsers automatically
                accept cookies. Still, if you prefer, you can change your browser to
                prevent that or notify you each time a cookie is set. You can also learn
                more about cookies by visiting www.allaboutcookies.org, which includes
                additional useful information on cookies and how to
                block cookies using different browsers or mobile devices. Please note,
                however, that by blocking or deleting cookies used on the Platform, you
                may not be able to take full advantage of the Platform.
            </p>

            <p>
                <strong>Our Contact Information</strong> <br />
                Please contact us with any questions or comments about this Policy, your
                personal information, our use and disclosure practices, or your consent
                choices by email at <a href="mailto:admin@onediamondmail.com">admin&#64;onediamondmail.com</a>.
            </p>
            <address class="mt-2">
                Turbo UPC <br />
                678 Front Ave NW Ste 215 <br />
                Grand Rapids, MI 49504
            </address>
        </div>
    </div>
</div>