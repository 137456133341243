import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from "@angular/core";
import { navbarData } from "./nav-data";
import { SessionService } from "src/app/services/session.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
interface sideBarItems {
  routerLink: string;
  icon: string;
  label: string;
}
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed: boolean = true;
  navData: sideBarItems[] = [];
  SidebarList: any = [];
  screenWidth = 0;
  logo: any;
  showLeftIcon = true;
  showRightIcon = false;
  currentUrl: string;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 993) {
      this.collapsed = false;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
    if (this.screenWidth >= 993) {
      this.collapsed = true;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
  }
  constructor(private sessionService: SessionService, private router: Router,) {  
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
    });
    
    
  }
  ngOnInit(): void {
    let userRole = this.sessionService.SessionService_GetUserDetails();
    let sideBarNavItems: sideBarItems[] = JSON.parse(
      JSON.stringify(navbarData)
    );
    let bool = userRole.role === "admin" ? true : false;
    if (!bool) {
      const labelsToRemove = [
        "Administration",
      ];

      labelsToRemove.forEach((label) => {
        const index = sideBarNavItems.findIndex((item) => item.label === label);
        if (index !== -1) {
          sideBarNavItems.splice(index, 1);
        }
      });
    }
    this.navData = sideBarNavItems;
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.onToggleSidenav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  closeSidenav() {
    this.collapsed = false;
    this.onToggleSidenav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(["/auth/login"]);
  }

}
