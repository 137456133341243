import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
// import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translate(100%,0%)' }),
        animate('300ms ease-in', style({ transform: 'translate(0%,0%)' }))
      ]),
      transition(':leave', [
        animate('1s ease-in', style({ transform: 'translate(0%,0%)' }))
      ])
    ])
  ]
})

export class AlertsComponent implements OnInit, OnDestroy {
  @Output() closeAlert = new EventEmitter<void>();
  @Input() resMessage;
  showAlert: boolean = true; // Set this to true to initially show the alert


  // *********************** ngOnInit ***********************

  ngOnInit() {
    document.addEventListener('click', this.onDocumentClick);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  // *********************** Functions ***********************

  onDocumentClick = (event: MouseEvent) => {
    const alertElement = document.getElementById('alertComponent');
    if (!alertElement.contains(event.target as Node)) {
      this.closeAlert.emit();
    }
  }

  closeAlertCardBox() {
    this.closeAlert.emit();
  }
}
