<!-- <div [@slideInOut] class="alert-position mt-3 shadow">
  <div class="custom-alert d-flex align-items-center {{resMessage.color}}-alert">
    <div class="alert-body mr-3 d-flex p-2 align-items-center"> 
      <span [ngClass]="resMessage.responseType === 'success'?'check-circle':'error-icon'"></span>
      {{resMessage.message}}</div>
  </div>
</div> -->

<!-- Updated alerts with images -->
<main>
  <div  class="rounded-4 updated-alert w-450 p-4 card shadow border" *ngIf="resMessage.responseType === 'success'">
    <div class="card-body text-center">
      <div class="text-center">
        <img  class="img-size" src="../../../../assets/images/icons/Featured Icons.png" alt="">
        <div class="text-center p-3 pt-0">
          <h4 class="mb-2">{{resMessage.message}}</h4>
          <span class="text-muted">Your action has been performed.</span>
        </div> 
    </div>
    <button class="btn btn-primary text-center w-100" (click)="closeAlertCardBox()">Got it</button>
    </div>
  </div>
  <div  class="rounded-4 updated-alert w-450 p-4 card shadow border"  *ngIf="resMessage.responseType ==='error' || resMessage.responseType ==='failed'">
    <div class="card-body text-center">
      <div class="text-center">
        <img class="img-size" src="../../../../assets/images/icons/connection-lost.png" alt="">
        <div class="text-center p-3 pt-0">
          <h4 class="mb-2">{{resMessage.message}}</h4>
        </div> 
    </div>
    <button class="btn btn-primary text-center w-100" (click)="closeAlertCardBox()">Got it</button>

    </div>
  </div>
</main>
