<div class="paginations d-flex justify-content-end align-item-center gap-5 mt-3">
    <div>
        <p class="mb-0 font-14">Showing {{start}}-{{numberOfItems}} of {{totalItems}} items</p>
    </div>
    <div class="d-flex">
        <div class="d-flex">
            <p class="mb-0 font-14 fw-500">Items per page: {{recordSize}}</p>

            <div ngbDropdown class="d-inline-block ml-2 items-dropdown">
                <div class="profile-dropdown" ngbDropdownToggle>
                    <!-- Add the dropdown icon here -->
                    <i class="fas fa-chevron-down pagination-icon"></i>
                </div>

                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button *ngFor="let item of pageItems" class="pl-35" ngbDropdownItem
                        (click)="onPageSizeChange(item)">
                        <div>{{item}}</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="pagination me-3">
        <div (click)="goToPreviousPage()" [class.disabled]="currentPage==1">
            <i class="fas fa-chevron-left " [ngClass]="currentPage==1 ?'': 'pagination-icon'"></i>
        </div>

        <div class="pagination-text font-14 fw-500">
            {{ currentPage }} of {{ totalPage==0?currentPage:totalPage }} pages
        </div>
        <div (click)="goToNextPage()" [class.disabled]="totalPage==currentPage || totalPage==0">
            <i class="fas fa-chevron-right"
                [ngClass]="totalPage==currentPage || totalPage==0?'': 'pagination-icon'"></i>
        </div>
    </div>
</div>