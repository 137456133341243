import { Directive, HostListener, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appPhoneNumberFormat]'
})
export class PhoneNumberFormatDirective implements AfterViewInit {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const digitsOnly = value.replace(/\D/g, '');
  
    let formattedValue = '';
    if (digitsOnly.length > 10) {
      const areaCode = digitsOnly.substring(0, 3);
      const prefix = digitsOnly.substring(3, 6);
      const lineNumber = digitsOnly.substring(6, 10);
      formattedValue = `(${areaCode}) ${prefix}-${lineNumber}`;
    } else if (digitsOnly.length > 6) {
      const areaCode = digitsOnly.substring(0, 3);
      const prefix = digitsOnly.substring(3, 6);
      const lineNumber = digitsOnly.substring(6);
      formattedValue = `(${areaCode}) ${prefix}-${lineNumber}`;
    } else if (digitsOnly.length > 3) {
      const areaCode = digitsOnly.substring(0, 3);
      const prefix = digitsOnly.substring(3);
      formattedValue = `(${areaCode}) ${prefix}`;
    } else {
      formattedValue = digitsOnly;
    }
  
    // Only update the input field if the formatted value is different
    if (this.el.nativeElement.value !== formattedValue) {
      this.el.nativeElement.value = formattedValue;
      // Trigger the 'input' event to update the ngModel/formControl
      this.el.nativeElement.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }
  

  // Format the initial value during initialization
  ngOnInit() {
    const value = this.el.nativeElement.value || '';
    this.onInput(value);
  }

  // Handle auto-filled input after the view has been initialized
  ngAfterViewInit() {
    const value = this.el.nativeElement.value || '';
    this.onInput(value);
  }
}
