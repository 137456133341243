import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent implements OnInit {
  @Input() currentPage: number;
  @Input() totalPage: number;
  @Input() totalItems: number;
  @Input() numberOfItems: number;
  @Input() start: number;
  @Input() user: boolean;
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
  pageItems: any = [100, 500, 1000]
  recordSize: number = 1000;
  constructor(private sessionService: SessionService) {
  }
  ngOnInit(): void {
    if (this.user == true) {
      const pageSizeLocalStorage = this.sessionService.SessionService_GetPageSize();
      if (pageSizeLocalStorage) {
        this.recordSize = pageSizeLocalStorage
      }
    }
    // else {
    //   this.recordSize = 1000;
    // }
  }

  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.pageChange.emit(this.currentPage - 1);
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPage) {
      this.pageChange.emit(this.currentPage + 1);
    }
  }

  onPageSizeChange(size: number) {
    if (this.user == true) {
      this.sessionService.SessionService_SetPageSize(size)
    }
    this.recordSize = size;
    this.pageSizeChange.emit(size);
  }
}
