import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ScrollService {
   private scrollSubject = new Subject<boolean>();
   scrollObservable = this.scrollSubject.asObservable();

   emitScrolling(isScrolling: boolean) {
      this.scrollSubject.next(isScrolling);
   }
}
