import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class ErrorService {
   private errorMessageSubject = new Subject<string>();

   errorMessage$: Observable<string> = this.errorMessageSubject.asObservable();

   setErrorMessage(errorMessage: string) {
      this.errorMessageSubject.next(errorMessage);
   }
}
