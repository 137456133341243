import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor() { }

  //set session expiry
  cSessionService_setSessionExpiry() {
    const nSessionExpiryTime: number = new Date().getTime() + 1800000;
    localStorage.setItem(
      "ls" + environment.APP_NAME + "SessionExpiryTime",
      JSON.stringify(nSessionExpiryTime)
    );
  }
  //get session expiry
  cSessionService_getSessionExpiry() {
    return JSON.parse(
      localStorage.getItem("ls" + environment.APP_NAME + "SessionExpiryTime")
    );
  }
  // set access token
  SessionService_SetAccessToken(oData: any) {
    localStorage.setItem(environment.AccessToken, JSON.stringify(oData));
  }
  // get access token
  SessionService_GetAccessToken() {
    return JSON.parse(localStorage.getItem(environment.AccessToken));
  }
  // set user details
  SessionService_SetUserDetails(oData: any) {
    localStorage.setItem("lsUserDetails", JSON.stringify(oData));
  }
  // get user details
  SessionService_GetUserDetails() {
    return JSON.parse(localStorage.getItem("lsUserDetails"));
  }

  //StoreReportsBody
  SessionService_SetReportsChartBody(oData) {
    localStorage.setItem("ReportsChartTableBody", JSON.stringify(oData));
  }
  SessionService_GetReportsChartBody() {
    return JSON.parse(localStorage.getItem("ReportsChartTableBody"));
  }

  SessionService_GetSelectedTenantFromHeader() {
    return JSON.parse(localStorage.getItem("lsSelectedTenant"));
  }
  //lsSetSelectedTenantType
  SessionService_SetSelectedTenantTypeFromHeader(oData) {
    localStorage.setItem("lsSelectedTenantType", JSON.stringify(oData));
  }
  SessionService_GetSelectedTenantTypeFromHeader() {
    return JSON.parse(localStorage.getItem("lsSelectedTenantType"));
  }

  // survery data
  SessionService_SetSurveyPayload(oData) {
    localStorage.setItem("sureveyFormList", JSON.stringify(oData));
  }
  SessionService_GetSurveyPayload() {
    return JSON.parse(localStorage.getItem("sureveyFormList"));
  }

  //lsGetInfoPayload
  SessionService_SetSearchAvailableItemsPayload(oData) {
    localStorage.setItem('SearchAvailableItemsPayload', JSON.stringify(oData));
  }
  SessionService_GetSearchAvailableItemsPayload() {
    return JSON.parse(localStorage.getItem('SearchAvailableItemsPayload'));
  }

  //Ios3DModel
  SessionService_SetIos3DModel(oData) {
    localStorage.setItem('lsIos3DModel', JSON.stringify(oData));
  }
  SessionService_GetIos3DModel() {
    return JSON.parse(localStorage.getItem('lsIos3DModel'));
  }

  //SelectedYearQnA
  SessionService_SetSelectedYearQnA(oData) {
    localStorage.setItem('SelectedYearQnA', JSON.stringify(oData));
  }
  SessionService_GetSelectedYearQnA() {
    return JSON.parse(localStorage.getItem('SelectedYearQnA'));
  }

  //set and get current page in local storage


  //SelectedYearQnA
  SessionService_SetCurrentPage(oData) {
    localStorage.setItem('currentPage', JSON.stringify(oData));
  }
  SessionService_GetCurrentPage() {
    return JSON.parse(localStorage.getItem('currentPage'));
  }

  //***********Storing and getting Current Page  */
  SessionService_SetPageSize(oData: any) {
    localStorage.setItem('pageSize', JSON.stringify(oData))
  }
  SessionService_GetPageSize() {
    return JSON.parse(localStorage.getItem('pageSize'));
  }


  // *~~~~~~~~~~~~~~~Storing and Getting Reports Listing~~~~~~~~~~~~~~~* 
  SessionService_SetBrands(oData: any) {
    sessionStorage.setItem('brands', JSON.stringify(oData))
  }

  SessionService_GetBrands() {
    return JSON.parse(sessionStorage.getItem('brands'));
  }

  SessionService_SetChains(oData: any) {
    sessionStorage.setItem('chains', JSON.stringify(oData))
  }

  SessionService_GetChains() {
    return JSON.parse(sessionStorage.getItem('chains'));
  }

  // *~~~~~~~~~~~~~~~Storing and Getting Dashboard Data~~~~~~~~~~~~~~~* 
  SessionService_SetBusinessSummaryData(oData: any) {
    sessionStorage.setItem('businessSummaryData', JSON.stringify(oData))
  }

  SessionService_GetBusinessSummaryData() {
    return sessionStorage.getItem('businessSummaryData');
  }

  SessionService_SetDailyDiamonds(oData: any) {
    sessionStorage.setItem('dailyDiamonds', JSON.stringify(oData))
  }

  SessionService_GetDailyDiamonds() {
    return JSON.parse(sessionStorage.getItem('dailyDiamonds'));
  }

  SessionService_SetPromoCalendar(oData: any) {
    sessionStorage.setItem('promoCalendar', JSON.stringify(oData))
  }

  SessionService_GetPromoCalendar() {
    return JSON.parse(sessionStorage.getItem('promoCalendar'));
  }

  SessionService_SetTopPerformersData(oData: any) {
    sessionStorage.setItem('topPerformersData', JSON.stringify(oData))
  }

  SessionService_GetTopPerformersData() {
    return (sessionStorage.getItem('topPerformersData'));
  }

  SessionService_SetPromotionalBrands(oData: any) {
    localStorage.setItem('promotionalBrands', JSON.stringify(oData))
  }

  SessionService_GetPromotionalBrands() {
    return JSON.parse(localStorage.getItem('promotionalBrands'))
  }

  SessionService_SetBrandNames(oData: any) {
    localStorage.setItem('brands', oData)
  }

  SessionService_SetChainNames(oData: any) {
    localStorage.setItem('chainNames', JSON.stringify(oData))
  }

  SessionService_GetChainNames() {
    return JSON.parse(localStorage.getItem('chainNames'))
  }

  SessionService_SetReportsData(oData: any) {
    localStorage.setItem('Reports Data', JSON.stringify(oData))
  }

  SessionService_GetReportsData() {
    return JSON.parse(localStorage.getItem('Reports Data'))
  }

  SessionService_SetRetailerData(oData: any) {
    localStorage.setItem('Retailer Data', JSON.stringify(oData))
  }

  SessionService_GetRetailerData() {
    return JSON.parse(localStorage.getItem('Retailer Data'))
  }

  SessionService_SetStoreLocationData(oData: any) {
    localStorage.setItem('Store Location Data', JSON.stringify(oData))
  }

  SessionService_GetStoreLocationData() {
    return JSON.parse(localStorage.getItem('Store Location Data'))
  }
  SessionService_SetTenantId(oData: any) {
    localStorage.setItem('tenantId', JSON.stringify(oData))
  }

  SessionService_GetTenantId() {
    return JSON.parse(localStorage.getItem('tenantId'))
  }
  SessionService_SetReportPayload(oData: any) {
    localStorage.setItem('VenRetailPayload', JSON.stringify(oData))
  }

  SessionService_GetReportPayload() {
    return JSON.parse(localStorage.getItem('VenRetailPayload'))
  }

  //For Bling Bot

  SessionService_SetSuggestion(oData: any) {
    localStorage.setItem('blingBotSuggestion', JSON.stringify(oData))
  }

  SessionService_GetSuggestion() {
    return JSON.parse(localStorage.getItem('blingBotSuggestion'))
  }

  SessionService_SetPdfUploadBoolean(oData: any) {
    localStorage.setItem('pdfBoolean', JSON.stringify(oData))
  }

  SessionService_GetPdfUploadBoolean() {
    return JSON.parse(localStorage.getItem('pdfBoolean'))
  }

  SessionService_SetFilenames(oData: any) {
    localStorage.setItem('fileNames', JSON.stringify(oData))
  }

  SessionService_GetFilenames() {
    return JSON.parse(localStorage.getItem('fileNames'))
  }
  SessionService_SetConversationId(oData: any) {
    localStorage.setItem('conversationId', JSON.stringify(oData))
  }

  SessionService_GetConversationId() {
    return JSON.parse(localStorage.getItem('conversationId'))
  }

  SessionService_SetTaskId(oData: any) {
    localStorage.setItem('inProgressPDF', JSON.stringify(oData))
  }

  SessionService_GetTaskId() {
    return JSON.parse(localStorage.getItem('inProgressPDF'))
  }

  SessionService_SetBooleanChatFromSetting(oData: any) {
    localStorage.setItem('pdfFromSetting', JSON.stringify(oData))
  }

  SessionService_GetBooleanChatFromSetting() {
    return JSON.parse(localStorage.getItem('pdfFromSetting'))
  }

  SessionService_PromotionalCalendarRoute(oData: any) {
    localStorage.setItem('Dashboard', JSON.stringify(oData))
  }

  SessionService_GetPromotionalCalendarRoute() {
    return JSON.parse(localStorage.getItem('Dashboard'))
  }

  SessionService_SetVendorName(oData: any) {
    localStorage.setItem('vendorName', JSON.stringify(oData))
  }

  SessionService_GetVendorName() {
    return JSON.parse(localStorage.getItem('vendorName'))
  }

}
