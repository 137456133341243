<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed':''">
    <div class="logo-container" [ngClass]="collapsed ? '':'d-flex align-items-center justify-content-center pad-y'">
        <button class="btn btn-default p-0 border-0" (click)="toggleCollapse()">
            <i class="fa-solid fa-bars font-22"></i>
        </button>
        <div class="logo-text d-flex align-items-center gap-2" [class.d-none]="!collapsed">
            <img src="../../../assets/images/turbo-logo.png" height="40" alt="">
            <!-- <p style="white-space: nowrap;" class="font-600 font-12 m-0">
                Turbo UPC Marketing
            </p> -->
        </div>
    </div>

    <ul class="sidenav-nav">
        <li class="sidenav-nav-item" *ngFor="let item of navData"
            [ngClass]="item.label == 'Seprator' ? 'custom-seperator':''">
            <a class="sidenav-nav-link px-2 " [routerLink]="item.routerLink" routerLinkActive="active"
                *ngIf="item.label != 'Seprator'" [class.justify-content-center]="!collapsed">
                <i class="sidenav-link-icon sidebar-icons-new fa-solid icon-size"[class]="item.icon" *ngIf="collapsed"></i>
                <i class="sidenav-link-icon sidebar-icons-new fa-solid" [ngbTooltip]="item.label"
                    style="margin: 1.2rem;" *ngIf="!collapsed" [class]="item.icon"></i>
                <span class="sidenav-link-text" *ngIf="collapsed">{{item.label}}</span>
            </a>
        </li>
    </ul>


    <div class="mt-2" routerLink="/usda-company-lookup" routerLinkActive="active">
        <p class="styled-button sidenav-button btn-color" [ngClass]="currentUrl=='/usda-company-lookup'?'activeBottom':''" *ngIf="collapsed" >
            <i class="fa-regular fa-building-wheat me-2"></i>USDA Lookup
        </p>
        <p *ngIf="!collapsed" [ngbTooltip]="'USDA Lookup'" class=" sidenav-nav-item">
            <!-- <i class="fa-solid  icon-size fa-gear icon-collapsed sidebar-icons-new"></i> -->
            <i class="fa-regular fa-building-wheat me-2 icon-collapsed sidebar-icons-new" [ngClass]="currentUrl=='/usda-company-lookup'?'activeIcon':''" ></i>
        </p>
    </div>
    <div class="mt-2" routerLink="/margin-calculator">
        <p class="styled-button sidenav-button btn-color" *ngIf="collapsed">
            <i class="fa-solid fa-grid-2-plus me-2"></i>Margin Calculator
        </p>
        <p *ngIf="!collapsed" [ngbTooltip]="'Margin'" class=" sidenav-nav-item">
            <!-- <i class="fa-solid  icon-size fa-gear icon-collapsed sidebar-icons-new"></i> -->
            <i class="fa-solid fa-grid-2-plus me-2 icon-collapsed sidebar-icons-new"></i>
        </p>
    </div>
    <div class="mt-2">
        <p class="styled-button sidenav-button" *ngIf="collapsed" (click)="logout()">
            <i class="fa-solid icon-size fa-right-from-bracket"></i>
            Log Out
        </p>
        <p *ngIf="!collapsed" class="collapsed sidenav-nav-item" [ngbTooltip]="'Log Out'" (click)="logout()">
            <i class="fa-solid icon-size fa-right-from-bracket icon-collapsed"></i>
            <!-- <img src="../../../../assets/images/logout.svg"
                alt="" style="margin-left: 26px;"> -->
        </p>
    </div>
    
</div>