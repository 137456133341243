import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { SessionService } from '../services/session.service';
import { Router } from '@angular/router';

@Injectable()
export class cHeadersInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService, private Router: Router,) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let headers = {
      'Accept': '*/*',
      'x-app-name': 'main',
      'x-org-name': 'cloud',
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + this.sessionService.SessionService_GetAccessToken(),
    };

    if (request.url.includes('ajaxLogin') || request.url.includes('GetUsdaEstRecordV5') || request.url.includes('GetEstDataV5')) delete headers['Authorization'];
    if (request.url.includes('/upload')) delete headers['content-type'];

    let requestWithHeaders = request.clone({ setHeaders: headers });

    return next.handle(requestWithHeaders)
    .pipe(retry(0), catchError((error: HttpErrorResponse)=> {      
        if((error.status == 401) && (error.error.redirectUrl == "/_login")){
          // alert('Your account might face suspension or deletion.');
            localStorage.clear();
            this.Router.navigateByUrl('/auth/401');
        } else if (error.statusText == "Unknown Error"){
          this.Router.navigateByUrl('/auth/502');
        }
        return next.handle(requestWithHeaders);
    }))
  }

}
