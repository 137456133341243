export const navbarData = [
  {
    routerLink: "/dashboard",
    icon: "home",
    label: "Dashboard",
  },
  {
    routerLink: '/view-daily-diamond',
    icon: "diamonds",
    label: 'Daily Diamonds'
  },
  {
    routerLink: "/reports",
    icon: 'reports',
    label: "Reports",
  },
  {
    routerLink: "/flyers-marketing",
    icon: 'flyers',
    label: "Flyers/Marketing",
  },
  // {
  //   routerLink: "/survey",
  //   icon: "survey",
  //   label: "Survey",
  // },
  {
    routerLink: "/help-center",
    icon: 'help-center',
    label: "Bling Bot",
  },
  {
    routerLink: "",
    icon: "",
    label: "Seprator",
  },
  {
    routerLink: "/apps/administration",
    icon: 'administration',
    label: "Administration",
  },
  {
    routerLink: "",
    icon: "",
    label: "Seprator",
  },
  {
    routerLink: "/settings",
    icon: "fa-light fa-gear",
    label: "Settings",
  },
  {
    routerLink: "/apps/about",
    icon: "about",
    label: "About",
  },
];
