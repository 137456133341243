import { Component, OnInit } from "@angular/core";
import { ScrollService } from "src/app/services/scroll.service";
interface SideNavToggle {
  collapsed: boolean;
}
@Component({
  selector: "app-full",
  templateUrl: "./full.component.html",
  styleUrls: ["./full.component.scss"],
})
export class FullComponent implements OnInit {
  constructor(private scrollService: ScrollService) { }
  isSideNavCollapsed = true;
  screenWidth: number = 0;
  onToggleSidenav(data: SideNavToggle): void {
    this.isSideNavCollapsed = data.collapsed;
  }
  ngOnInit(): void { }
  onScroll(): void {
    this.scrollService.emitScrolling(true);
  }
}
