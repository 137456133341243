import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { cHeadersInterceptor } from './interceptors/headers.interceptor';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { SharedModule } from './modules/shared/shared.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DatePipe } from '@angular/common';
import { ScrollService } from './services/scroll.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxCaptureModule } from 'ngx-capture';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AudioRecordingService } from './services/audio-recording.service';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    BlankComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxCaptureModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    AudioRecordingService,
    ScrollService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS, useClass: cHeadersInterceptor, multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
