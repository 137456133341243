<app-custom-alerts *ngIf="bDisplayErrorBlock && copyTextMessage" [resMessage]="resMessage"></app-custom-alerts>
<app-alerts *ngIf="bDisplayErrorBlock && !copyTextMessage" (closeAlert)="closeAlert()" id="alertComponent"
  [resMessage]="resMessage"></app-alerts>
  <div class="upload-status-modal shadow" *ngIf="!displayUploadModal && uploadingModal.show  " >
    <div class="modal-header">
      <i class="fa-solid fa-window-minimize" (click)="toggle()"></i>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-2 d-flex justify-content-center align-items-center">
          <i class="fa-solid fa-cloud-arrow-up fa-beat"></i>
        </div>
        <div class="col-10">
          <h4>Uploading....</h4>
          <p>Just give us a moment to upload your files.</p>
          <ngb-progressbar
            class="mb-3"
            type="success"
            [value]="uploadingModal.trueCount + uploadingModal.falseCount"
            [max]=" uploadingModal.fileLength"
            [striped]="true"
          />
        </div>
  
        <div class="offset-2 col-10">
          <p class="mb-1">
            <i class="fa-solid fa-circle-check text-success mr-2"></i> Matched: {{uploadingModal.trueCount}}
          </p>
          <p class="mb-1">
            <i class="fa-solid fa-circle-xmark text-danger mr-2"></i>Miss Matched:
            {{uploadingModal.falseCount}}
          </p>
          <p class="mb-1">
            <i class="fa-brands fa-wolf-pack-battalion mr-2"></i>Total: {{uploadingModal.fileLength}}
          </p>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="displayUploadModal && uploadingModal.show " class="minizime-upload">
    <div class="d-flex justify-content-between align-items-center">
      <p class="m-0">Uploading in progress</p>
      <i class="fa-solid fa-expand cursor-pointer" (click)="toggle()"></i>
    </div>
  </div>
<div class="head" [ngClass]="toggleSidebar ? 'head-trimmed' : ''">
  <div class="bg-white header-padd-15 d-flex justify-content-between align-items-center end_heading">
    <p class="font-500 font-24 m-0 mr-1 displayLabel header_heading">Turbo UPC</p>
    <div class="d-flex justify-content-end align-items-center end_heading">
      <div class="input-group col-md-8">
        <span class="search-icon">
          <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
          <img src="../../../../assets/images/searchIcon.svg" height="30" width="30" alt="">
        </span>
        <input type="text" placeholder="Type to Search" class="form-control search-input"
          (click)="blingbotQuickChat(quickChat)" />
        <span class="input-group-addon">
          <img src="../../../../assets/images/chat-ai.png" alt="">
        </span>
      </div>

      <div class="icon-container" appClickOutside (clickOutside)="clickOutside()">
        <div (click)="displayNotifications()">
         <i class="fa-sharp fa-regular fa-bell font-28 px-1 cursor-pointer head-notify-icon"></i>
          <ng-container (click)="getNotificationViewUpdate()">
            <div class="notification-badge" *ngIf="notificationCount > 0">
              {{ notificationCount > 99 ? "99+" : notificationCount }}
            </div>
          </ng-container>
        </div>
        <div *ngIf="bDisplayNotification" class="notification-list border-bottom p-0">
          <div class="notification-header p-4 d-flex justify-content-between align-items-center">
            <h5 class="mb-0 text-white font-24">Notifications</h5>
            <div>
              <i class="fa-solid fa-bell"></i>
            </div>
          </div>
          <div class="notification-scroller" (scroll)="onScroll($event)">
            <ul class="p-0">
              <li class="border-bottom p-3" *ngFor="let item of lnotifications" [class]="item.new ? 'newNotify' : ''">
                <div [class]="item.new ? 'newNotify' : ''">
                  <div class="d-flex">
                    <div class="me-2">
                    <!-- <div class="user-image notification-user-img me-2"> -->
                      <!-- <span class="font-20 text-center">{{
                        item.userName.charAt(0) | titlecase
                        }}</span> -->
                        <!-- Check if userPictureUrl exists -->
                        <ng-container *ngIf="item.userPictureUrl !== null && item.userPictureUrl !== undefined">
                        <!-- If userPictureUrl exists, show the image -->
                        <img [src]="item.userPictureUrl" alt="{{ item.userName }}" class="profile-width-height object-fit-cover" (error)="loadNotificationDummyImage(item)">
                        </ng-container>
                        <ng-container *ngIf="item.userPictureUrl === null || item.userPictureUrl === undefined">
                        <!-- If userPictureUrl doesn't exist, show the first letter of userName -->
                        <div class="font-20 text-center profile-width-height">{{ item.userName.charAt(0) | titlecase }}</div>
                        </ng-container>
                    </div>
                    <div class="notifications">
                      <div class="d-flex align-items-center justify-content-between">
                        <span class="font-600 font-16 mt-1">{{
                          item.userName | titlecase
                          }}</span>
                      </div>
                      <div>
                        <span class="font-14">{{ item.text }} </span>
                      </div>
                      <span class="font-12 time">{{
                        item.createdAt | date : "short"
                        }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div ngbDropdown class="d-inline-block ml-2" [ngClass]="
          oUserdetails.role != 'admin' ? 'settingNotDisplayToUser' : ''
        ">
        <div class="profile-dropdown" ngbDropdownToggle>
          <i class="fa-solid fa-gear"></i>
        </div>

        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="menus">
          <button class="py-2" ngbDropdownItem routerLink="/usermanager/users">
            <i class="fa-solid fa-users me-2"></i>
            Users
          </button>

          <button class="py-2" ngbDropdownItem routerLink="/usermanager/tenants" [ngClass]="
              oUserdetails.tenantId != '1' ? 'settingNotDisplayToUser' : ''
            ">
            <i class="fa-sharp fa-solid fa-house-laptop me-2"></i>
            Tenants
          </button>
        </div>
      </div> -->
      <!-- user -->
      <div ngbDropdown class="d-inline-block ml-2">
        <div class="profile-dropdown" ngbDropdownToggle>
          <i *ngIf="!profileImg" class="fa-solid fa-user"></i>
          <img *ngIf="profileImg" class="profile-width-height object-fit-cover" [src]="profileImg" (error)="loadDropdownProfile()"/>
        </div>

        <div ngbDropdownMenu aria-labelledby=" dropdownBasic1" class="menus">
          <button ngbDropdownItem class="py-2 gap-3 text-center pe-none border-bottom">
            <div class="d-flex p-2">
              <div class="round-image shadow mr-2">
                <span *ngIf="!profileImg" class="font-20 font-700 text-center">{{ oUserdetails?.userId[0] | titlecase
                  }}</span>
                <img *ngIf="profileImg" class="name-width-height object-fit-cover" [src]="profileImg"
                  (error)="loadAvatar()" alt="driver" />
              </div>
              <div class="d-flex pt-1 flex-column align-items-start justify-items-start">
                <span>{{ oUserdetails?.userId | titlecase }}</span>
                <span class="font-12 font-300" style="color: gray !important">{{
                  oUserdetails?.role | titlecase
                  }}</span>
              </div>
            </div>
          </button>
         

          <button class="py-2" ngbDropdownItem (click)="UpdateProfileModal(updateProfile)">
            <i class="fa-solid fa-user mr-2"></i>
            Profile
          </button>
          <button *ngIf="oUserdetails.role=='admin'" class="py-2" ngbDropdownItem [routerLink]="'/apps/team-members'">
            <i class="fa-solid fa-users mr-2"></i>
            Team Members
          </button>

          <button class="py-2" ngbDropdownItem (click)="ChangePasswordModal(changePassword)">
            <i class="fa-solid fa-key mr-2"></i>
            Change Password
          </button>

          <button class="py-2" ngbDropdownItem (click)="HeaderComponent_logout()">
            <i class="fa-solid fa-right-from-bracket mr-2"></i>
            Logout
          </button>
        </div>
      </div>
      <!-- userName -->
      <div class="name_display">
        <div class="d-flex pt-1 ml-2 flex-column align-items-center justify-items-start flex-wrap">
          <span class="font-500 d-inline-block text-truncate" style="max-width: 150px;">
            <!-- {{ oUserdetails?.fullName | titlecase }} -->
            {{ (oUserdetails?.firstName + ' ' + oUserdetails?.lastName) | titlecase }}
          </span>
          <!-- Other content -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Change Password modal -->
<ng-template #changePassword let-modal ngbModalBackdrop (click)="dismiss()">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="changePasswordForm">
      <div class="row">
        <div class="form-group mb-3 col-md-12">
          <label for="exampleFormControlInput1" class="form-label">
            User ID</label>
          <input type="text" id="Userid" placeholder="User Id" class="form-control input" formControlName="userId" />
        </div>

        <div class="form-group mb-3 col-md-12">
          <label for="exampleFormControlInput1" class="form-label">
            Current Password</label>
          <div class="position-relative showPasswordIcon">
            <input type="text" id="Currentpassword" (input)="onChange()" formControlName="oldPassword"
              placeholder="Current Password" class="form-control input" [type]="passwordMode ? 'text' : 'password'"
              onkeypress="return (event.charCode !=32 )" />
            <span (click)="showCurrentPassword()" class="field-icon toggle-password" [ngClass]="
                !passwordMode ? 'fa-regular fa-eye-slash' : 'fa fa-fw fa-eye '
              "></span>
          </div>
          <small *ngIf="
              changePasswordForm.controls['oldPassword'].hasError('required') &&
              changePasswordForm.controls['oldPassword'].touched &&
              errorCode != 401
            " class="text-danger">Current Password is required</small>
          <small *ngIf="
            changePasswordForm.controls['oldPassword'].errors?.['minlength'] && errorCode!=401
            " class="text-danger support-text">The input value is shorter than 8 characters</small>
          <small *ngIf="errorCode == 401" class="text-danger">Current Password is wrong</small>
        </div>
        <div class="form-group mb-3 col-md-12">
          <label for="exampleFormControlInput1" class="form-label">
            New Password</label>
          <div class="position-relative showPasswordIcon">
            <input type="text" id="Newpassword" formControlName="password" placeholder="New Password"
              class="form-control input" [type]="newPasswordMode ? 'text' : 'password'"
              onkeypress="return (event.charCode !=32 )" />
            <span (click)="showNewPassword()" class="field-icon toggle-password" [ngClass]="
                !newPasswordMode
                  ? 'fa-regular fa-eye-slash'
                  : 'fa fa-fw fa-eye '
              "></span>
          </div>

          <small *ngIf="
              changePasswordForm.controls['password'].hasError('required') &&
              changePasswordForm.controls['password'].touched
            " class="text-danger support-text">New Password is required</small>
          <small *ngIf="
            changePasswordForm.controls['password'].errors?.['minlength']
            " class="text-danger support-text">The input value is shorter than 8 characters</small>
        </div>

        <div class="form-group mb-3 col-md-12">
          <label for="exampleFormControlInput1" class="form-label">
            Repeat New Password</label>
          <div class="position-relative showPasswordIcon">
            <input type="text" id="Repeatpassword" formControlName="rptPassword" placeholder=" Repeat New Password"
              class="form-control input" [type]="repeatPasswordMode ? 'text' : 'password'"
              onkeypress="return (event.charCode !=32 )" />
            <small *ngIf="
                changePasswordForm.controls['rptPassword'].hasError(
                  'required'
                ) && changePasswordForm.controls['rptPassword'].touched
              " class="text-danger support-text">Repeat New Password is required</small>
            <span (click)="showRepeatPassword()" class="field-icon toggle-password" [ngClass]="
                !repeatPasswordMode
                  ? 'fa-regular fa-eye-slash'
                  : 'fa fa-fw fa-eye '
              "></span>
          </div>
          <small *ngIf="
              error && changePasswordForm.controls['rptPassword'].value != ''
            " class="text-danger">New password and repeat password do not match.</small>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer position-relative">
    <button [disabled]="bLoading" type="button" class="btn btn-secondary me-2 loader-placement" (click)="dismiss()">
      Cancel
    </button>
    <div *ngIf="bLoading" class="">
      <img src="../../../../assets/images/turbo-nloader.gif" height="40" alt="">
      <!-- <app-diamond-loader></app-diamond-loader> -->
    </div>
    <button *ngIf="!bLoading" type="button" class="btn btn-primary" (click)="changeUserPassword()">
      Submit
    </button>
  </div>
</ng-template>

<!-- Update Profile Modal -->
<ng-template #updateProfile let-modal>
  <div class="modal-header">
    <h4 class="modal-title modal-header-font">Edit Profile</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="changeNameForm">
      <div class="form-row">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-center">
            <!-- avatar -->

            <div class="profile-avatar">
              <img *ngIf="!userProfileImage" class="separate-width-height object-fit-cover" [src]="imageUrl"
                (error)="loadAvatar()" alt="driver" />
              <img *ngIf="userProfileImage" class="separate-width-height object-fit-cover" style="border: none"
                [src]="userProfileImage" (error)="loadAvatar()" alt="profile image" />
              <span class="edit-profile-img d-flex align-items-center justify-content-center">
                <div class="upload-btn-wrapper">
                  <input class="d-none" id="uploadfile" accept=".jpeg,.jpg,.png" type="file" name="myfile"
                    (change)="onFileSelect($event)" />
                  <label for="uploadfile"><i class="fa-solid fa-pen cursor-pointer"></i></label>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group col-md-12 mb-3">
          <label class="form-label">First Name</label>
          <input type="text" class="form-control" placeholder="First Name" formControlName="firstName" />
          <small *ngIf="
              changeNameForm.controls['firstName'].hasError('required') &&
              changeNameForm.controls['firstName'].touched
            " class="text-danger">Please enter First Name</small>
          <small *ngIf="
              changeNameForm.controls['firstName'].hasError('pattern') &&
              changeNameForm.controls['firstName'].dirty
            " class="text-danger">First Name cannot start or end with spaces</small>
        </div>
        <div class="form-group col-md-12 mb-3">
          <label class="form-label">Last Name</label>
          <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName" />
          <small *ngIf="
              changeNameForm.controls['lastName'].hasError('required') &&
              changeNameForm.controls['lastName'].touched
            " class="text-danger">Last Name is required</small>
          <small *ngIf="
              changeNameForm.controls['lastName'].hasError('pattern') &&
              changeNameForm.controls['lastName'].dirty
            " class="text-danger">Last Name cannot start or end with spaces</small>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex gap-2">
    <button type="button" class="btn btn-sm btn-secondary mr-2" (click)="dismissModal()">
      Cancel
    </button>
    <button type="button" class="btn btn-sm btn-primary ml-2" [disabled]="uploadImage" (click)="saveProfile()">
      Submit
    </button>
  </div>
</ng-template>

<!-- Blingbot Quich chat Modal -->
<ng-template #quickChat let-modal>
  <div class="modal-header d-flex flex-column">
    <i class="fa-thin fa-xmark align-self-end icon-cross" (click)="dismissModal()"></i>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-baseline gap-2">
        <h3 class="modal-title "><img class="chat-ai" src="../../../../assets/images/chat-ai.png" alt="">
          BlingBot</h3>
        <h6>Quick Chat</h6>
      </div>
      <div class="ml-4">
        <button class="btn btn-light" (click)=" HeaderComponent_NavigateToBlingBot()"><img class="me-2 mb-1"
            src="../../../../assets/images/icons/bulb.svg" alt="">Go To Chat Screen
          <img class="ms-2" src="../../../../assets/images/icons/arrow-forward.svg" alt=""></button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="chat-bot-area" id="scrollContainer">
      <div class="row g-0" *ngIf="!chatLoader && messagesWithAI.length == 0">
        <div class="col-md-12 d-flex justify-content-center align-items-center">
          <div class="card shadow w-50 mt-3">
            <div class="card-body d-flex justify-content-center">
              <div>
                <h4 class="font-16 mb-0">
                  Kindly provide an initial input to commence our conversation.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let item of messagesWithAI; let i=index">
        <div class=" p-3 position-relative border-bottom">
          <img class="img-position" [src]="userProfileImage" (error)="HeaderComponent_LoadAvatar()" alt="">
          <p class="px-5 padding-top mb-2">{{item.question}}</p>
        </div>
        <div class="position-relative p-3 border-bottom" *ngIf="HeaderComponent_IsAtLeastOneKeyNotEmpty(item)">
          <div class="img-position-bot">
            <img src="../../../../assets/images/icons/blingBotIcon.svg" alt="">
          </div>
          <div class="pad-left padding-top">
            <h4 *ngIf="item?.heading!=''&& item?.heading">{{item?.heading}}</h4>
            <p class="mb-0" *ngIf="item?.answer!=''&& item?.answer">{{item?.answer}}</p>
            <div *ngIf="item?.rows.length>0 && item?.rows" class="table-responsive chat-Table-heigth">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th scope="col" *ngFor="let header of item.tableHeaders">{{ header }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of item.rows">
                    <td scope="row" *ngFor="let header of item.tableHeaders">{{ row[header] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <figure *ngIf=" item?.images" class="image-gallery">
              <img *ngFor="let image of item?.images;let i =index" [src]="HeaderComponent_GetErrorImage(i) || image"
                alt="" (error)="HeaderComponent_HandleError(i)">
            </figure>
            <ul class="suggestion-list list-group shadow w-100" *ngIf="item.suggestions?.length>0">
              <li *ngFor="let suggestion of item?.suggestions"
                class="list-group-item list-group-item-action cursor-pointer"
                (click)="HeaderComponent_SelectSuggestion(suggestion)"> {{ suggestion }}</li>
            </ul>
          </div>

          <!-- <div class="d-flex icons-poistion gap-3">
                <i placement="top" ngbTooltip="Copy Text" class="fa-sharp fa-light fa-clipboard-list fs-5 cursor-pointer" (click)="copyTextToClipboard(i)"></i>
                <i class="fa-thin fa-thumbs-up fs-5 cursor-pointer"></i>
                <i class="fa-thin fa-thumbs-down fs-5 cursor-pointer"></i>
            </div> -->
        </div>
      </div>
    </div>
    <div class="position-static" *ngIf="chatLoader">
      <div class="text-center">
        <div class="loading-dots--dot me-2"></div>
        <div class="loading-dots--dot me-2"></div>
        <div class="loading-dots--dot"></div>
      </div>
      <div class="card-body">
      </div>
    </div>
    <ul class="suggestion-list position-absolute sugg-scroller list-group"
      *ngIf="suggestions.length>0 && suggestions && !chatLoader" appClickOutside
      (clickOutside)="HeaderComponent_CloseSuggestionList()">
      <li *ngFor="let item of suggestions" class="list-group-item list-group-item-action cursor-pointer"
        (click)="HeaderComponent_SelectSuggestion(item)"> {{ item }}</li>
    </ul>
    <div class=" custom-text-area" [ngClass]="this.chatLoader?'disable':''">
      <textarea *ngIf="!isAudioRecording && !disableIocns && !micText"
        class="tracking-in-expand form-control custom-textarea" rows="1" placeholder="Send a message..."
        [(ngModel)]="question" (keydown)="HeaderComponent_ConversationWithAIModelOnEnter($event)"
        (input)="HeaderComponent_OnTextareaInput($event);adjustTextareaHeight($event)"
        [readOnly]="this.chatLoader"></textarea>
      <textarea *ngIf="isAudioRecording && !micText" class="tracking-in-expand form-control custom-textarea" rows="1"
        placeholder="Speak now" readonly></textarea>
      <textarea *ngIf="micText" class="tracking-in-expand form-control custom-textarea" rows="1"
        placeholder="Listening..." readonly></textarea>
      <textarea *ngIf="!isAudioRecording && disableIocns && !micText"
        class="tracking-in-expand form-control custom-textarea" rows="1" placeholder="Wait..." readonly></textarea>
      <div class="bot-microphone"><i class="fa-regular fa-microphone"
          [ngClass]="isAudioRecording?'microphone-glowing':!isAudioRecording && disableIocns && !micText?'disable':''"
          (click)="isAudioRecording?stopAudioRecording():startAudioRecording()"></i></div>
      <div class="send-text" [ngClass]="disableIocns?'disable':''">
        <i class="bling-send-upload ms-2 fa-solid fa-paper-plane-top" (click)="HeaderComponent_StartChatWithAI()"></i>
      </div>
    </div>
  </div>

</ng-template>