import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-diamond-loader',
  templateUrl: './diamond-loader.component.html',
  styleUrls: ['./diamond-loader.component.scss']
})
export class DiamondLoaderComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }


}
