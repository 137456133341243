import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BrowserCompatibilityService } from './services/browser-compatibility.service';
import { ErrorService } from './services/error.handler.service';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'one-diamond';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  oldBrowserVersion: boolean;
  broswerNameVersion: { browserName: string; browserVersion: number; };
  bDisplayErrorBlock: boolean = false;
  resMessage: { message: any; responseType: any; color: any };

  constructor(private checkIdleState: Idle, private errorService: ErrorService, private keepalive: Keepalive, private oRouter: Router, private broswerVersionService: BrowserCompatibilityService, 
    private dataService: DataService,
    ) { }

  resetTimer() {
    this.checkSessionExpiry()
    this.checkIdleState.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnInit() {
    // Read 'rememberMe' directly from localStorage
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    // Set the timeout based on 'rememberMe'
    const timeoutDuration = rememberMe ? 604800 : 14400;
    // Call the method to set session expiry time
    this.AppComponent_SetSessionExpiryTime(timeoutDuration);
  }

  AppComponent_SetSessionExpiryTime(oIncomingTime) {
    this.oldBrowserVersion = this.broswerVersionService.isOldBrowser();
    this.broswerNameVersion = this.broswerVersionService.getBrowserNameAndVersion();
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.checkIdleState.setIdle(5);
    // sets a timeout period of 30 minutes. After 60 minutes and 5 seconds of inactivity, the user will be considered timed out.
    this.checkIdleState.setTimeout(oIncomingTime);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.checkIdleState.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.checkIdleState.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      //  console.log("On idle end Idle state : ==> ", this.idleState);
    });
    this.checkIdleState.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.displayAlertMessage("Session timed out Please log in again.", "error", "danger");
      //  console.log("On Time out Idle state : ==> ", this.idleState);
      localStorage.clear();
      this.oRouter.navigateByUrl('/auth/login');
      this.resetTimer();
    });
    this.checkIdleState.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      //  console.log("On idle start Idle state : ==> ", this.idleState);
    });
    this.checkIdleState.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log("on Timeout warning Idle state : ==> ", this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(5);

    this.keepalive.onPing.subscribe(
      () => {
        let TimeOutForApplication = oIncomingTime;
        let rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
        rememberMe == true ? TimeOutForApplication = 604800 : TimeOutForApplication = 14400;
        this.lastPing = new Date();
        const sessionExpiryTime = this.lastPing.getTime() + TimeOutForApplication * 1000;
        localStorage.setItem("oneDiamondSessionExpiryTime", JSON.stringify(sessionExpiryTime))
      });

    this.resetTimer();
  }

  checkSessionExpiry() {
    const oldDate = new Date();
    const sessionExpiryTime = JSON.parse(localStorage.getItem("oneDiamondSessionExpiryTime"));
    const currentTime = oldDate.getTime();

    if (sessionExpiryTime !== null) {
      // console.log("checkSessionExpiry: Value of lsOneDiamondSessionExpiryTime {%s}", sessionExpiryTime);
      // console.log("checkSessionExpiry: Current Time {%s}", nCurrentTime);
      // compare the expiry time of the item with the current time
      if (currentTime > sessionExpiryTime) {
        localStorage.removeItem("oneDiamondSessionExpiryTime");
        localStorage.clear();
        // console.log("checkSessionExpiry: Current Session is expired. So, logging out");
        this.oRouter.navigateByUrl('/auth/login');
      }
    }
  }

  closeAlert() {
    this.bDisplayErrorBlock = false;
  }

  displayAlertMessage(
    sIncommingMessage,
    sIncommingResponseType,
    sIncommingColor
  ) {
    this.bDisplayErrorBlock = true;
    this.resMessage = {
      message: sIncommingMessage,
      responseType: sIncommingResponseType,
      color: sIncommingColor,
    };
    setTimeout(() => {
      this.bDisplayErrorBlock = false;
    }, 3000);
  }

}

