import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
@Component({
  selector: 'app-custom-alerts',
  templateUrl: './custom-alerts.component.html',
  styleUrls: ['./custom-alerts.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translate(100%,0%)' }),
        animate('300ms ease-in', style({ transform: 'translate(0%,0%)' }))
      ]),
      transition(':leave', [
        animate('1s ease-in', style({ transform: 'translate(0%,0%)' }))
      ])
    ])
  ]
})
export class CustomAlertsComponent {
  @Input() resMessage;
}
